@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  overflow-x: hidden;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
button {
  outline: none;
}

/*
@font-face {
    font-family: 'Iron & Brine';
    src: url('../assets/fonts/IronBrine.eot');
    src: url('../assets/fonts/IronBrine.eot?#iefix') format('embedded-opentype'), 
    url('../assets/fonts/IronBrine.woff2') format('woff2'), 
    url('../assets/fonts/IronBrine.woff') format('woff'), 
    url('../assets/fonts/IronBrine.ttf') format('truetype'), 
    url('../assets/fonts/IronBrine.svg#Iron&Brine') 
    format('svg');
    font-weight: normal;
    font-style: normal;
}
*/
@font-face {
  font-family: "Soleil";
  src: url("../assets/fonts/Soleil Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Soleil Light";
  src: url("../assets/fonts/Soleil Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten";
  src: url("../assets/fonts/Tungsten-Book.otf") format("opentype");
  font-stretch: semi-expanded;
  font-weight: bolder;
}
* {
  font-family: "Soleil";
}

.border-color {
  border-color: #ef7622;
}

.sidebar--sub {
  background-color: #002E5D;
}
.sidebar--sub__item {
  font-family: "Soleil";
}
.sidebar--sub__item--selected {
  font-weight: 600 !important;
}
.sidebar__item {
  font-family: "Soleil";
  color: #3a3a3a;
}

.item *,
.modal *,
.mobile-filter *,
.filter *,
.header *,
.footer * {
  font-family: "Soleil";
}

.discover__header {
  width: 100%;
  border-bottom: 2px solid;
  margin-bottom: 30px;
}
.discover__name {
  font-size: 16px;
  font-weight: 700;
  max-width: 489px;
  margin-bottom: 20px;
  line-height: 1.3;
}
.discover__label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 18px;
}
.discover__text {
  font-family: "Soleil";
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 15px;
  max-width: 600px;
  line-height: 1.3;
  color: #4e4e4e;
}
.discover__text--bold {
  font-weight: 700;
  margin-bottom: 10px;
}
.discover__item {
  margin-bottom: 20px;
}
.discover__item::before {
  content: "•";
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.discover__link {
  font-family: "Soleil";
  color: inherit;
}

.page {
  position: fixed;
  left: 0;
  transition: 0.5s all ease;
}
.page--menuOpen {
  left: 165px;
}

.content {
  background: fixed transparent url("../assets/bg-rodeo-tablet-2024.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}
.content-inner {
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 24px 32px;
}
.content-inner__title {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 8px;
}
.content-inner__subtitle {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 32px;
}
.content-inner__label {
  font-family: "Soleil";
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar-wrapper {
  display: flex;
  flex: 0 0 165px;
}

.sub-sidebar-wrapper {
  display: flex;
  flex: 0 0 280px;
}

.form__input {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 18px;
  border-radius: 4px;
  font-size: 16px;
  color: #3a3a3a;
  border: 1px solid #3a3a3a;
}
.form__input::placeholder {
  opacity: 0.8;
}
.form__textarea {
  width: 100%;
  height: 200px;
  padding: 15px;
  margin-bottom: 18px;
  border-radius: 4px;
  font-size: 16px;
  color: #3a3a3a;
  border: 1px solid #3a3a3a;
}
.form__textarea::placeholder {
  opacity: 0.8;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.btn--submit {
  width: 100%;
  height: 40px;
  background: #ef7622;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}
.btn--outline {
  font-size: 16px;
  width: 200px;
  height: 40px;
  background: transparent;
  border: 1px solid #ef7622;
  border-radius: 4px;
}
.btn:disabled {
  opacity: 0.7;
  pointer-events: none;
}

/*
This .scss loop will create "margin helpers" and "padding helpers"
It will generate several classes such as:
.mr-10 which gives margin-right 10 pixels.
.pb-5 gives PADDING to the BOTTOM of 5 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/
.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.m-55 {
  margin: 55px !important;
}

.p-55 {
  padding: 55px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.m-60 {
  margin: 60px !important;
}

.p-60 {
  padding: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.m-65 {
  margin: 65px !important;
}

.p-65 {
  padding: 65px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.m-70 {
  margin: 70px !important;
}

.p-70 {
  padding: 70px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.m-75 {
  margin: 75px !important;
}

.p-75 {
  padding: 75px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.m-80 {
  margin: 80px !important;
}

.p-80 {
  padding: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.m-85 {
  margin: 85px !important;
}

.p-85 {
  padding: 85px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.m-90 {
  margin: 90px !important;
}

.p-90 {
  padding: 90px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.m-95 {
  margin: 95px !important;
}

.p-95 {
  padding: 95px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.m-100 {
  margin: 100px !important;
}

.p-100 {
  padding: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.t-hidden {
  display: none !important;
}

.m-hidden {
  display: block !important;
}
.m-hidden--fx {
  display: flex !important;
}

@media (max-width: 991px) {
  .content {
    background: fixed transparent url("../assets/bg-rodeo-mobile-2024.jpg") no-repeat center;
    background-size: cover;
  }
  .content-inner__label {
    margin: 32px 0 10px !important;
    font-size: 28px;
    text-align: center;
  }

  .item {
    min-height: 500px !important;
  }

  .title-set {
    font-size: 14px;
  }

  .color-sample {
    width: 80% !important;
  }

  .image-admin-wrapper {
    height: 185px !important;
  }
  .image-admin-btn button {
    width: 150px !important;
  }
}
@media (max-width: 767px) {
  .item__name {
    font-size: 16px !important;
    margin: 15px 0 !important;
  }

  .modal__img {
    height: 180px !important;
    background-position: center center !important;
    margin-right: 0 !important;
    width: auto !important;
    margin-bottom: 15px;
  }
  .modal__name {
    text-align: center;
  }
  .modal__wrapper {
    width: 100% !important;
  }

  .image-admin-wrapper {
    padding: 0 !important;
  }
  .image-admin-btn button {
    width: 100% !important;
    font-size: 12px !important;
  }

  .btn--buy {
    width: 100px;
    margin: 15px 0;
  }

  .footer {
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .page {
    position: relative;
  }

  .t-hidden {
    display: block !important;
  }
  .t-hidden--fx {
    display: flex !important;
  }

  .m-hidden {
    display: none !important;
  }

  .content-inner {
    height: auto;
    padding: 15px 15px 30px;
  }

  .header {
    padding: 0 15px !important;
  }
  .header__nav {
    padding: 32px 15px !important;
  }

  .item {
    margin: 0 0 15px 0 !important;
    min-height: auto !important;
  }
  .item__property {
    height: auto !important;
    text-align: left !important;
  }
  .item__property__img {
    width: 100px;
  }
  .item__name {
    text-align: left !important;
  }
  .item__number {
    position: relative !important;
  }

  .modal {
    padding: 15px 15px 45px !important;
  }
  .modal__wrapper {
    width: 100% !important;
    padding: 30px !important;
    overflow-y: scroll;
  }

  .home {
    background: fixed transparent url("../assets/bg-rodeo-mobile-2024.jpg") no-repeat center;
    display: block !important;
    padding: 15px 15px 30px !important;
  }
  .home__title {
    font-size: 26px !important;
  }
  .home__button__title {
    font-size: 16px !important;
  }
  .home__button__subtitle {
    font-size: 20px !important;
  }

  .content {
    background: fixed transparent url("../assets/bg-rodeo-mobile-2024.jpg") no-repeat center;
    background-size: cover;
  }
  .content-inner__label {
    font-size: 24px;
  }

  .btn--preview {
    margin: 0 5px 5px 0;
    width: 48%;
  }

  .footer {
    padding-right: 15px !important;
  }
}
html,
body {
  height: 100%;
}

.mat-slider-horizontal {
  width: 100%;
}
.mat-slider-horizontal .mat-slider-wrapper {
  top: 100px;
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: #ef7622;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #ef7622 !important;
}

/* MODIFICATIONS */
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

.mat-slider-horizontal .mat-slider-track-wrapper {
  background: transparent;
  border-top: 5px dashed #ef7622;
}

.mat-slider-track-wrapper .mat-slider-track-fill {
  background: #AB9E88;
  border-top: 5px dashed #ef7622;
}

.sidebar--sub__logo {
  display: none;
}