// Rodeo
/*
@font-face {
    font-family: 'Iron & Brine';
    src: url('../assets/fonts/IronBrine.eot');
    src: url('../assets/fonts/IronBrine.eot?#iefix') format('embedded-opentype'), 
    url('../assets/fonts/IronBrine.woff2') format('woff2'), 
    url('../assets/fonts/IronBrine.woff') format('woff'), 
    url('../assets/fonts/IronBrine.ttf') format('truetype'), 
    url('../assets/fonts/IronBrine.svg#Iron&Brine') 
    format('svg');
    font-weight: normal;
    font-style: normal;
}
*/
@font-face {
    font-family: 'Soleil';
    src: url('../assets/fonts/Soleil\ Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Soleil Light';
    src: url('../assets/fonts/Soleil\ Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('../assets/fonts/Tungsten-Book.otf') format('opentype');
    font-stretch:semi-expanded;
    font-weight: bolder;

}