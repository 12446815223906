// Color
$black: #000000;
$white: #ffffff;
$gray: #4e4e4e;
$primary-color: var(--first-color);
$secondary-color: var(--second-color);
$tertiary-color: var(--third-color);
$rodeo-gray: #f1f1f1;

//from rodeo website
$rodeo-primary: #002E5D;
$rodeo-secondary: #3a3a3a;
$rodeo-orange: #ef7622;

// Font Size
$font-10: 10px;
$font-12: 12px;
$font-14: 14px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-26: 26px;
$font-28: 28px;
$font-36: 36px;
$font-40: 40px;
$font-48: 48px;
// Font Weight
$light: 300;
$regular: 400;
$semiBold: 600;
$bold: 700;
$black: 900;
// Radius
$radius-2: 2px;
$radius-4: 4px;
// Transition
$transition: .3s ease-in-out;

//new fonts (LT-Dec 2023)
$font-primary: 'Soleil';
$font-secondary: 'Tungsten';
