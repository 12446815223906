/*
This .scss loop will create "margin helpers" and "padding helpers"
It will generate several classes such as:
.mr-10 which gives margin-right 10 pixels.
.pb-5 gives PADDING to the BOTTOM of 5 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }
  .p-#{$space} {
    padding: #{$space}px !important;
  }
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

//Font sizes
$sizes: (10, 12, 14, 16, 18, 20);
@each $size in $sizes {
  .font-#{$size} {
    font-size: #{$size}px;
  }
}

//Font weight
$weights: (bold, normal);
@each $weight in $weights {
  .#{$weight} {
    font-weight: #{$weight};
  }
}

//Font transform
$transforms: (lowercase, uppercase, capitalize);
@each $transform in $transforms {
  .#{$transform} {
    text-transform: #{$transform};
  }
}

//Text align
$aligns: (right, left, center);
@each $align in $aligns {
  .text-#{$align} {
    text-align: #{$align};
  }
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.t-hidden {
  display: none !important;
}

.m-hidden {
  display: block !important;

  &--fx {
    display: flex !important;
  }
}
