// @import 'variables';
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;600;700;900&display=swap');
// .sale {
//     background-color: $tertiary-color;
// }

// :root {
//     --first-color: #000080;
//     --second-color: #f1dfbf;
//     --third-color: #002E5D;
// }

// body {
//     font-family: 'Lato', sans-serif!important;
//     font-size: $font-16;
// }

// .border-color {
//     border-color: $secondary-color;
// }

// .sidebar {
//     &--sub {
//         background-color: $secondary-color;
//         &__item {
//             &--selected {
//                 font-weight: $semiBold !important;
//             }
//         }
//     }
//     &__item {
//         color: $tertiary-color;
//     }
// }

// // .item *,
// // .modal *,
// // .mobile-filter *,
// // .filter *,
// // .header *,
// // .footer * {}
// .discover {
//     &__header {
//         width: 100%;
//         border-bottom: 2px solid;
//         margin-bottom: 30px;
//     }
//     &__name {
//         font-size: $font-16;
//         font-weight: $bold;
//         max-width: 489px;
//         margin-bottom: 20px;
//         line-height: 1.3;
//     }
//     &__label {
//         font-size: $font-16;
//         font-weight: $bold;
//         margin-bottom: 18px;
//     }
//     &__text {
//         font-size: $font-16;
//         font-weight: $light;
//         margin-bottom: 15px;
//         max-width: 600px;
//         line-height: 1.3;
//         color: $gray;
//         &--bold {
//             font-weight: $bold;
//             margin-bottom: 10px;
//         }
//     }
//     &__item {
//         margin-bottom: 20px;
//         &::before {
//             content: '\2022';
//             font-weight: $bold;
//             display: inline-block;
//             width: 1em;
//             margin-left: -1em;
//         }
//     }
//     &__link {
//         color: inherit;
//     }
// }

// .page {
//     position: fixed;
//     left: 0;
//     transition: 0.5s all ease;
//     &--menuOpen {
//         left: 165px;
//     }
//     &.content {
//         background-position: fixed!important;
//         background-color: transparent!important;
//         background-repeat: no-repeat!important;
//         background-size: cover!important;
//     }
// }

// .content {
//     //background: fixed transparent url('../assets/bg-rodeo-tablet.png') no-repeat center;
//     background-size: cover;
//     width: 100%;
//     min-height: 100vh;
//     &-inner {
//         width: 100%;
//         height: 100vh;
//         overflow: auto;
//         padding: 24px 32px 50px;
//         &__title {
//             font-size: $font-36;
//             font-weight: $bold;
//             margin-bottom: 8px;
//         }
//         &__subtitle {
//             font-size: $font-24;
//             font-weight: $light;
//             margin-bottom: 32px;
//         }
//         &__label {
//             display: flex;
//             justify-content: center;
//             font-size: $font-40;
//             font-weight: $bold;
//             text-transform: uppercase;
//             color: $tertiary-color;
//             font-weight: $black;
//             margin: 0 0 10px;
//         }
//     }
// }

// .sidebar-wrapper {
//     display: flex;
//     flex: 0 0 165px;
// }

// .sub-sidebar-wrapper {
//     display: flex;
//     flex: 0 0 280px;
// }

// .form {
//     &__input {
//         width: 100%;
//         height: 40px;
//         padding: 0 15px;
//         margin-bottom: 18px;
//         border-radius: $radius-4;
//         font-size: $font-16;
//         color: $primary-color;
//         border: 1px solid $primary-color;
//         &::placeholder {
//             opacity: 0.8;
//         }
//     }
//     &__textarea {
//         width: 100%;
//         height: 200px;
//         padding: 15px;
//         margin-bottom: 18px;
//         border-radius: $radius-4;
//         font-size: $font-16;
//         color: $primary-color;
//         border: 1px solid $primary-color;
//         &::placeholder {
//             opacity: 0.8;
//         }
//     }
// }

// .btn {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     cursor: pointer;
//     &--submit {
//         width: 100%;
//         height: 40px;
//         background: $primary-color;
//         color: $white;
//         font-weight: $bold;
//         text-transform: uppercase;
//         border: none;
//         border-radius: $radius-4;
//         font-size: $font-16;
//     }
//     &--outline,
//     &--buy {
//         font-size: $font-16;
//         width: 200px;
//         height: 40px;
//         background: transparent;
//         border: 1px solid $primary-color;
//         border-radius: $radius-4;
//         color: $primary-color;
//     }
//     &--customization {
//         background: transparent;
//         font-size: $font-16;
//         position: absolute;
//         right: 16px;
//         top: 16px;
//         opacity: 0.5;
//         border: none;
//         &:hover {
//             opacity: 1;
//         }
//     }
//     &:disabled {
//         opacity: 0.7;
//         pointer-events: none;
//     }
//     &--buy {
//         background: $tertiary-color;
//         border: 1px solid $tertiary-color;
//         color: #fff;
//         width: 50%;
//         margin: 15px auto 0;
//         &:hover {
//             color: #fff;
//             background: $secondary-color;
//             border: 1px solid $secondary-color;
//         }
//     }
//     &--submit {
//         &:hover {
//             color: rgba(#fff, 0.50);
//         }
//     }
//     &--preview {
//         display: inline-flex;
//         background: $tertiary-color;
//         border: none;
//         border-radius: $radius-4;
//         color: #fff;
//         margin: 0 5px 0 0;
//         font-size: $font-14;
//         &:hover,
//         &:focus,
//         &:active {
//             color: #fff;
//             background: $secondary-color;
//         }
//     }
// }

// .pos-relative {
//     position: relative;
// }

// .swal2 {
//     &-popup {
//         border-radius: $radius-4!important;
//         &.stepsAlert {
//             padding: 0 0 15px 0;
//             .swal2-header {
//                 padding: 0;
//                 border-bottom: 1px solid #cecece;
//                 .swal2-image {
//                     margin: 0;
//                 }
//             }
//             &.stepsAlertProgress {
//                 .swal2-progress-steps {
//                     margin: 25px 0;
//                     .swal2-progress-step {
//                         background: $tertiary-color;
//                         opacity: 0.7;
//                         &-line {
//                             background: $tertiary-color;
//                             opacity: 0.7;
//                         }
//                     }
//                     .swal2-active-progress-step {
//                         background: $tertiary-color;
//                         opacity: 1;
//                     }
//                 }
//                 .swal2-title {
//                     font-size: 24px!important;
//                     margin: 15px 0;
//                 }
//             }
//         }
//     }
//     &-title {
//         color: $tertiary-color!important;
//         font-size: $font-24!important;
//         font-family: 'Lato', sans-serif!important;
//     }
//     &-input {
//         height: 40px!important;
//         font-size: $font-16!important;
//     }
//     &-actions {
//         button {
//             &.swal2-confirm {
//                 border-radius: 4px!important;
//                 background: initial!important;
//                 background-color: $tertiary-color!important;
//             }
//         }
//     }
// }

// .noResults {
//     width: 100%;
//     text-align: center;
//     margin-top: 25px;
//     font-weight: 500;
//     font-size: 22px;
//     padding: 0 15px;
// }

// .state-of-shipping-text {
//     text-align: center;
//     font-size: $font-18;
//     margin-bottom: 15px;
// }

// .header {
//     &__select {
//         color: $tertiary-color;
//         border-color: $tertiary-color;
//         padding: 3px;
//         border-radius: $radius-4;
//         width: 100%;
//     }
//     &__shipping {
//         color: $tertiary-color;
//         font-size: $font-16;
//         font-weight: $semiBold;
//         margin-bottom: 8px;
//     }
// }

@import 'variables';

* {
  font-family: $font-primary;
}

.border-color {
  border-color: $rodeo-orange;
}

.sidebar {
  &--sub {
    background-color: $rodeo-primary;

    &__item {
      font-family: $font-primary;

      &--selected {
        font-weight: $semiBold !important;
      }
    }
  }

  &__item {
    font-family: $font-primary;
    color: $rodeo-secondary;
  }
}

.item *,
.modal *,
.mobile-filter *,
.filter *,
.header *,
.footer * {
  font-family: $font-primary;
}

.discover {
  &__header {
    width: 100%;
    border-bottom: 2px solid;
    margin-bottom: 30px;
  }

  &__name {
    font-size: $font-16;
    font-weight: $bold;
    max-width: 489px;
    margin-bottom: 20px;
    line-height: 1.3;
  }

  &__label {
    font-size: $font-16;
    font-weight: $bold;
    margin-bottom: 18px;
  }

  &__text {
    font-family: $font-primary;
    font-size: $font-16;
    font-weight: $light;
    margin-bottom: 15px;
    max-width: 600px;
    line-height: 1.3;
    color: $gray;

    &--bold {
      font-weight: $bold;
      margin-bottom: 10px;
    }
  }

  &__item {
    margin-bottom: 20px;

    &::before {
      content: '\2022';
      font-weight: $bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  &__link {
    font-family: $font-primary;
    color: inherit;
  }
}

.page {
  position: fixed;
  left: 0;
  transition: 0.5s all ease;

  &--menuOpen {
    left: 165px;
  }
}

.content {
  background: fixed transparent url('../assets/bg-rodeo-tablet-2024.jpg') no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;

  &-inner {
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding: 24px 32px;

    &__title {
      font-size: 35px;
      font-weight: $bold;
      margin-bottom: 8px;
    }

    &__subtitle {
      font-size: $font-24;
      font-weight: $light;
      margin-bottom: 32px;
    }

    &__label {
      font-family: $font-primary;
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-weight: $bold;
      text-transform: uppercase;
    }
  }
}

.sidebar-wrapper {
  display: flex;
  flex: 0 0 165px;
}

.sub-sidebar-wrapper {
  display: flex;
  flex: 0 0 280px;
}

.form {
  &__input {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    margin-bottom: 18px;
    border-radius: $radius-4;
    font-size: 16px;
    color: $rodeo-secondary;
    border: 1px solid $rodeo-secondary;

    &::placeholder {
      opacity: 0.8;
    }
  }

  &__textarea {
    width: 100%;
    height: 200px;
    padding: 15px;
    margin-bottom: 18px;
    border-radius: $radius-4;
    font-size: 16px;
    color: $rodeo-secondary;
    border: 1px solid $rodeo-secondary;

    &::placeholder {
      opacity: 0.8;
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  &--submit {
    width: 100%;
    height: 40px;
    background: $rodeo-orange;
    color: $white;
    font-weight: $bold;
    text-transform: uppercase;
    border: none;
    border-radius: $radius-4;
    font-size: 16px;
  }

  &--outline {
    font-size: 16px;
    width: 200px;
    height: 40px;
    background: transparent;
    border: 1px solid $rodeo-orange;
    border-radius: $radius-4;
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
