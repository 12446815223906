    @media (max-width: 991px) {
        .content {
            background: fixed transparent url('../assets/bg-rodeo-mobile-2024.jpg') no-repeat center;
            background-size: cover;
            &-inner {
                &__label {
                    margin: 32px 0 10px!important;
                    font-size: $font-28;
                    text-align: center;
                }
            }
        }
        .item {
            min-height: 500px!important;
        }
        .title-set {
            font-size: $font-14;
        }
        .color-sample {
            width: 80%!important;
        }
        .image-admin {
            &-wrapper {
                height: 185px!important;
            }
            &-btn {
                button {
                    width: 150px!important;
                }
            }
        }
    }
    
    @media (max-width: 767px) {
        .item {
            &__name {
                font-size: $font-16!important;
                margin: 15px 0!important;
            }
        }
        .modal {
            &__img {
                height: 180px!important;
                background-position: center center!important;
                margin-right: 0!important;
                width: auto!important;
                margin-bottom: 15px;
            }
            &__name {
                text-align: center;
            }
            &__wrapper {
                width: 100%!important;
            }
        }
        .image-admin {
            &-wrapper {
                padding: 0!important;
            }
            &-btn {
                button {
                    width: 100%!important;
                    font-size: $font-12!important;
                }
            }
        }
        .btn {
            &--buy {
                width: 100px;
                margin: 15px 0;
            }
        }
        .footer {
            margin-top: 15px;
        }
    }
    
    @media (max-width: 575px) {
        .page {
            position: relative;
        }
        .t-hidden {
            display: block !important;
            &--fx {
                display: flex !important;
            }
        }
        .m-hidden {
            display: none !important;
        }
        .content {
            &-inner {
                height: auto;
                padding: 15px 15px 30px;
            }
        }
        .header {
            padding: 0 15px !important;
            &__nav {
                padding: 32px 15px !important;
            }
        }
        //Items component
        .item {
            margin: 0 0 15px 0 !important;
            min-height: auto!important;
            &__property {
                height: auto!important;
                text-align: left!important;
                &__img {
                    width: 100px;
                }
            }
            &__name {
                text-align: left!important;
            }
            &__number {
                position: relative!important;
            }
        }
        .modal {
            padding: 15px 15px 45px !important;
            &__wrapper {
                width: 100% !important;
                padding: 30px !important;
                overflow-y: scroll;
            }
        }
        //Home component
        .home {
            background: fixed transparent url('../assets/bg-rodeo-mobile-2024.jpg') no-repeat center;
            display: block !important;
            padding: 15px 15px 30px !important;
            &__title {
                font-size: $font-26!important;
            }
            &__button {
                &__title {
                    font-size: $font-16!important;
                }
                &__subtitle {
                    font-size: $font-20!important;
                }
            }
        }
        .content {
            background: fixed transparent url('../assets/bg-rodeo-mobile-2024.jpg') no-repeat center;
            background-size: cover;
            &-inner {
                &__label {
                    font-size: $font-24;
                }
            }
        }
        .btn {
            &--preview {
                margin: 0 5px 5px 0;
                width: 48%;
            }
        }
        .footer {
            padding-right: 15px !important;
        }
    }